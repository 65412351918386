html,body{
    font-size: 62.5%;
}
.tools-item::after{
    content: '';
    width: 1px;
    height: 20px;
    background-color: #aaa;
    position: absolute;
    right: 0;
    top: 8px;
}
