

@tailwind base;
@layer base {
    h1 {
      @apply text-4xl font-bold;
    }
    h2 {
      @apply text-3xl font-bold mt-5;
    }
    h3{
      @apply text-2xl  font-bold;
    }
    h4{
      @apply text-xl font-bold;
    }
    h5{
      @apply text-base  font-bold;
    }
    button {
      @apply bg-inherit;
    }
}
@tailwind components;
.top-bar{
  @apply h-32 bg-gray-300  px-4;
  background: #121B34;
  color: #fff;
}
.blue-1{
  @apply text-sky-500;
}
.gray-1{
  @apply text-gray-900;
}
.gray-2{
  @apply text-gray-700;
}
.red-1 {
  @apply text-red-500
}
.yellow-1 {
  @apply text-yellow-500
}
.green-1 {
  @apply text-emerald-500
}
@tailwind utilities;

@import '~antd/dist/antd.css';